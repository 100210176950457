/* General styling for the container */
.container-fluid {
  padding: 0;
}

/* Sidebar styling */
.sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #f8f9fa; /* Adjust as needed */
  padding-top: 20px;
  /* Optional styling for sidebar */
}

/* Main content area */
.main-content {
  margin-left: 0; /* Default for small screens */
  padding: 20px;
}

/* Apply margin-left of 250px for larger screens */
@media (min-width: 992px) {
  .main-content {
    margin-left: 250px;
  }
}
@media (max-width: 800px) and (min-width: 679px){
  .main-content {
    margin-left: 100px;
    margin-top: 50px;
  }
}