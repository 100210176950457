/* General Styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  /* Sidebar Styles for permanent and temporary state */
  .sidebar {
    width: px; /* Default width for larger screens */
    background-color: #333;
    color: white;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Temporary sidebar for mobile view (hidden) */
  .MuiDrawer-paper {
    width: 240px;
    background-color: #333;
    color: white;
    box-sizing: border-box;
  }
  
  /* Content Area */
  .content {
    margin-left: 240px; /* Default margin for larger screens */
    padding: 20px;
    transition: margin-left 0.3s ease-in-out;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .sidebar {
      width: 200px; /* Sidebar is slightly smaller on mobile */
    }
  
    .content {
      margin-left: 0;
    }
  }
  